import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "colors",
      "style": {
        "position": "relative"
      }
    }}>{`Colors`}</h1>
    <TabLinks items={[{
      title: 'Pallete',
      href: '/design-guidelines/color'
    }, {
      title: 'Usage',
      href: '/design-guidelines/color/usage'
    }]} mdxType="TabLinks" />
    <p>{`Our color palettes were built with a focus on accessibility and ease of use. Through them, it is possible to
build accessible components and even dark UI by inverting the color levels.`}</p>
    <h2 {...{
      "id": "consistency",
      "style": {
        "position": "relative"
      }
    }}>{`Consistency`}</h2>
    <p>{`It is possible to change the primary color of the components by keeping the contrast proportion because all
the scales were built with similar amounts of brightness on each level.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/color-consistency.png",
        "alt": "Color consistency on buttons: Same level of brightness"
      }}></img></p>
    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}</h2>
    <p>{`The scales are divided into 10 levels: the colors from 10 to 40 have a contrasting warranty of > 4.5:1 in
white backgrounds, while the ones from 70 to 100 have the same warranty in dark backgrounds. In this way, our
components respect the adequate contrast proportions for textual and interactive elements and non-interactive
elements, in accordance with any palette.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/color-contrast.png",
        "alt": "Color contrast ratios"
      }}></img></p>
    <h2 {...{
      "id": "dark-ui",
      "style": {
        "position": "relative"
      }
    }}>{`Dark UI`}</h2>
    <p>{`All the palettes were built to work in an inverted way. In order to apply the dark mode just invert the colors
from the center of the scale, what is 50 becomes 60, what is 40 becomes 70, and so it goes. For a practical
example, just click the lamp on our header!`}</p>
    <p><img parentName="p" {...{
        "src": "/image/color-darkui.png",
        "alt": "Inverted gray scale"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      